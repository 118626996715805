import * as React from "react";
import { Builder } from "@builder.io/react";

export const ImgURL = (props) => (
  <img src={props.URL} loading="lazy" />
);

Builder.registerComponent(ImgURL, {
  name: "Img URL",
  inputs: [
    {
      name: "URL",
      type: "string",
      defaultValue: "",
    }
  ],
});