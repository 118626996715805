import React from 'react';
import loadable from "@loadable/component"
import { BuilderComponent, builder, Builder } from '@builder.io/react';
const LoadableMediaElement = loadable(() => import('../../VideoURL/MediaElement'))

export const Video = (props) => {
  const {
    VideoURL,
    playerID,
    Subtitles,
    thumbnail,
    caption
  } = props;

  const sources = [
    {
      src: VideoURL,
      type: "video/mp4"
    }
  ],
  config = {},
  tracks = Subtitles;
  return <div style={{width: "80%", display: "flex", justifyContent: "center", margin: "auto"}}>
<figure style={{width: "100%"}}>
<LoadableMediaElement
      id={playerID}
      mediaType="video"
      preload="metadata"
      controls
      width="700"
      height="404"
      thumbnail={thumbnail}
      sources={JSON.stringify(sources)}
      options={JSON.stringify(config)}
      tracks={JSON.stringify(tracks)} />
    <figcaption style={{color: "rgba(117, 117, 117, 1)", fontSize: "14px", textAlign: "center"}}>{caption}</figcaption>
        
</figure>

  </div>;
}