import React from 'react';
import loadable from "@loadable/component"
const LoadableMediaElement = loadable(() => import('./MediaElement'))

export const VideoURL = (props) => {
  const {
    VideoURL,
    playerID,
    Subtitles,
    thumbnail
  } = props;

  const sources = [
    {
      src: VideoURL,
      type: "video/mp4"
    }
  ],
  config = {},
  tracks = Subtitles;
  return <div style={{ position: "relative" }}>

    <LoadableMediaElement
      id={playerID}
      mediaType="video"
      preload="metadata"
      controls
      width="700"
      height="404"
      thumbnail={thumbnail}
      sources={JSON.stringify(sources)}
      options={JSON.stringify(config)}
      tracks={JSON.stringify(tracks)} />
    
  </div>;
}
