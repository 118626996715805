import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import Link from '../components/Link/Link';
import '../builder-settings';
import { Helmet } from 'react-helmet';


const query = graphql`
  query {
    allBuilderModels {
      header(options: { cachebust: true }) {
        name
        content
      }
      footer(options: { cachebust: true }) {
        name
        content
      }
      
    }
  }
`;


function PageLayout({ children, location }) {
  try {
    var pathname = location.pathname.split("/")[1].split("_")[0]
  } 
  catch {
    var pathname = "en"
  }
  return (
    <StaticQuery query={query}>
      {data => {
        const models = data.allBuilderModels;
        let header = models.header[0].content;
        let footer = models.footer[0].content;
        for (var i = 0; i < models.header.length; i++) {
          if (models.header[i].name.split(" ")[1].toLowerCase() == pathname) {
            header = models.header[i].content;
          }
        }
        for (var i = 0; i < models.footer.length; i++) {
          if (models.footer[i].name.split(" ")[1].toLowerCase() == pathname) {
            footer = models.footer[i].content;
          }
        }
        let page;
        try {
          page = children.props.allBuilderModels.page[0].content
        } catch (e) {}
        console.log()
        return (
          <div style={{overflow: "hidden"}}>
            {page &&
              <Helmet>
                <title>{page.data.title}</title>
                <meta
                  name="description"
                  content={
                    page.data.description
                  }
                />
                <meta property="og:image" content={page.data.metaImage} />
              </Helmet>
            }
              
            <div >
              <BuilderComponent
                renderLink={Link}
                name="header"
                content={header}
              />
            </div>
            <div >{children}</div>
            <div >
              <BuilderComponent
                renderLink={Link}
                name="footer"
                content={footer}
              />
            </div>
          </div>
        );
      }}
    </StaticQuery>
  );
}

export default PageLayout;
