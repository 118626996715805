import * as React from "react";
import { Builder } from "@builder.io/react";
export const Header = (props) => {
  console.log(props)
    return (
        <div  >
          <img style={{width: "100%"}} src={props["Hero Image URL"]} />
          <div className='' style={{paddingTop: "26px", paddingBottom: "64px", color: "#fff"}}>
            <h1 style={{fontSize: "50px", fontWeight: 700, textAlign: "center"}}>{props.Headline ? props.Headline : props.defaultHeadline}</h1>
            <h2 style={{ marginTop: "0.79em", fontWeight: 400, fontSize: "22px", textAlign: "center"}}>{props["Sub Headline"]}</h2>
            <div className='by-line' style={{fontWeight: 300, display: "flex", justifyContent: "space-around"}}>
              <div className=''>
                {"Written by: " + props.builderState.content.data.author}
              </div>
              {props.builderState.content.data.date &&
                <div className=''>
                  {"Last Updated: " + props.builderState.content.data.date}
                </div>
              }
            </div>
          </div>
          
          <div className='background' style={{position: "absolute", zIndex: -1, width: "200vw", height: "calc(100% + 20px)", background: "#1298DD", transform: "translateX(-50%)", top: "-20px", left: 0}}>
             
          </div>
        </div>

)};

Builder.registerComponent(Header, {
  name: "Blog Header",
  inputs: [
    {
      name: "Headline",
      type: "string",
      defaultValue: "",
    },
    {
        name: "Sub Headline",
        type: "string",
        defaultValue: "",
      },
    {
        name: "Hero Image URL",
        type: "string",
        defaultValue: "",
    }
  ],
});