import { Builder } from '@builder.io/react';
import { VideoURL } from './VideoURL';

Builder.registerComponent(VideoURL, {
  name: 'VideoURL',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image: 'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'VideoURL',
      type: 'string',
      defaultValue: 'https://dh392mqc7umpg.cloudfront.net/Exploring_the_Instant_Learning_Server.mp4'
    },
    {
      name: 'playerID',
      type: 'string',
      defaultValue: 'player1'
    },
    {
      name: 'thumbnail',
      type: 'string',
      defaultValue: ''
    },
    {
      name: "Subtitles",
      type: "list",
      defaultValue: [{src: "https://dh392mqc7umpg.cloudfront.net/vtt/5010020_1_1_Why_GDPR.vtt", label: "English", srclang: "en", kind: "subtitles"}],
      subFields: [
          { name: "src", type: "string" },
          { name: "label", type: "string" },
          { name: "srclang", type: "string" },
          { name: "kind", type: "string" }
      ],
    }
  ]
});
