import { Builder } from '@builder.io/react';
import { UdemyReviews } from './UdemyReviews';

Builder.registerComponent(UdemyReviews, {
  name: 'UdemyReviews',
  inputs: [
    {
      name: 'urlName',
      type: 'string',
      defaultValue: 'excel-10-things-you-should-know'
    },
    {
      name: 'width',
      type: 'number',
      defaultValue: 25
    },
  ]
});
