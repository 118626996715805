import { builder } from '@builder.io/react';

import '@builder.io/widgets';
import './components/Blog/Header/Header';
import './components/Blog/Img/Img';
import './components/Blog/Text/Text';
import './components/Blog/Video/video.builder';


import './components/ImgUrl/ImgUrl';
import './components/VideoURL/VideoURL.builder';
import './components/UdemyReviews/UdemyReviews.builder';
import './components/Button/ButtonMUI.builder';


import config from './config';
builder.init(config.builderAPIKey);
