import React from 'react';
import { Helmet } from 'react-helmet';
import './style.sass'

export default function RootLayout(props) {
  if (typeof window !== 'undefined') {
    if (window.location.pathname == "/") {
      window.location = '/en_us/';
    }
  }
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="theme-color" content="#f8f8f8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=yes"
        />
        <meta name="icon" href="/static/favicon.ico" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="image" content="/static/upskill-employees.png" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500&display=swap" />
        <link rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500&display=swap"
          media="print" onload="this.media='all'" />
        
      </Helmet>
        {props.children}
    </React.Fragment>
  );
}
