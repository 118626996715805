import { Builder } from '@builder.io/react';
import { ButtonMUI } from './ButtonMUI';

Builder.registerComponent(ButtonMUI, {
  name: 'ButtonMUI',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  inputs: [
    {
      name: 'buttonLabel',
      type: 'string',
      defaultValue: ''
      
    },
    {
        name: 'buttonLink',
        type: 'string',
        defaultValue: 'https://example.com'
    },
    {
      name: 'active',
      type: 'boolean',
      defaultValue: false
    },
    {
        name: 'variant',
        enum: ['contained', 'outlined', 'text']
    }
  ]
});
