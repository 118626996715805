import React from 'react';
import './UdemyReviews.sass'
const reviewsData = require('../../../reviews.json');

export const UdemyReviews = props => {
  const {
    urlName,
    width
  } = props;
  var reviews = reviewsData
  var rating = 0
  var num_reviews = 0

  try {
    for (let i = 0; i < reviews.results.length; i++) {
        const course = reviews.results[i];
        if (course.url.split("/")[2] == urlName) {
          num_reviews = course.num_reviews
          rating = course.rating
        }
      }
  } catch (error) {
    num_reviews = 0
    rating = 0
  } 
  
  var stars = []
  for (let index = 0; index < parseInt(rating); index++) {
    stars.push(<div key={"i_" + index} style={{overflow: "hidden"}}>
    <svg className="staricon" style={{width: 18}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245">
    <path d="m56,237 74-228 74,228L10,96h240"/>
    </svg></div>)
  }
  var fraction = (rating % 1) * 100
  stars.push(<div key={"fraction"} style={{overflow: "hidden", width: ((width / 100) * fraction)}}><svg className="staricon" style={{width: 18}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245">
  <path d="m56,237 74-228 74,228L10,96h240"/>
  </svg></div>)

  return (
    <div style={{display: "flex"}}>
      {stars}
    </div>
  );
};
