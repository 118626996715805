import React from 'react';
import './ButtonMUI.sass'

export const ButtonMUI = props => {
  const {
    buttonLink,
    buttonLabel,
    variant,
    icon,
    active
  } = props;

  let activeClass = active ? "active" : ""
  return (
    <div
        className={"button " + activeClass}
        style={{padding: "5px 10px", cursor: "pointer"}}
        variant={variant}
        href={buttonLink}
    >
        {buttonLabel}
    </div>
  );
};
