import * as React from "react";
import { Builder } from "@builder.io/react";

export const Text = (props) => (
  <p style={{fontSize: "20px", lineHeight: "28px"}}>{props.Text}</p>
);

Builder.registerComponent(Text, {
  name: "Blog Text",
  inputs: [
    {
      name: "Text",
      type: "string",
      defaultValue: "",
    }
  ],
});